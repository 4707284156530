/******************************************************************************\
 * Copyright(c) 2021 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: App.tsx
 *
 * Purpose: Initialize the PDS App
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 01Feb2023 craig   file created
 * 02May2023 sasjxa  add Contact Us
 * 17May2023 sasjxa  add new routes for share data
 * 23May2023 sasjxa  add new route for NCI request
 * 13Jun2023 sasjxa  add new route for submission response
 * 15Jun2023 sasjxa  add new route for data provider
 * 19Jun2023 sasjxa  add new routes for NCI download and upload
 * 15Jul2023 sasjxa  add new routes for data sharing select files
 * 09Aug2023 sasjxa  change route for dsa upload
 * 18Aug2023 sasjxa  add new route for admin tickets
 * 30Aug2023 sasjxa  add new routes for shared data RWD
 * 10Oct2023 sasjxa  remove userId from DataShareAgreement
 * 20Nov2023 sasjxa  add new route for NCI Requests View
 * 29Nov2023 sasjxa  add new route for admin ticket processing
 * 10Dec2023 craig   Added ApprovedUserRoute for logged in users
 * 15Dec2023 sasjxa  correct url for AdminTicketAction
 * 03Jan2024 craig   Added useLocation so we can get back to requested page
 *                   after authentication for protected pages
 * 17Jan2024 sasjxa  add request parms for NCI upload and download methods
 * 23Jan2024 craig   Added /registration/:key for expired accounts
 * 08Feb2024 craig   Added resetpassword
 * 29Feb2024 sasjxa  add new route for contribution contents (MPM-5392)
 * 07Mar2024 craig   Changed route so url matches current production (MPM-5430)
 * 20Mar2024 sasjxa  fix login url (MPM-5430)
 * 25Mar2024 sasjxa  add new route for expired RMI token (MPM-5402)
 * 15Apr2024 craig   Added new route for MyAccount (MPM-5422)
 * 17Apr2024 sasjxa  add new route for EmailPreferences (MPM-5422)
 * 18Apr2024 sasjxa  add new route for ServiceTickets (MPM-5422)
 * 04May2024 craig   Added NoMatch for missing pages
 * 05Jun2024 craig   Added routes for handling disabled/inactive/locked account
 * 12Jul2024 sasjxa  add new route for admin nci requests (MPM-5566)
 * 16Aug2024 craig   moved registration/expired/ back to no auth route
 * 21Aug2024 craig   moved to useContext(PDSContext) for user session
 * 03Sep2024 craig   removed console.log
 * 08Oct2024 sasjxa  add new route to display Clinical Drug description
 * 06Nov2024 craig   Moved clinicalDrugAdditivity to admin level until ready
 \******************************************************************************/
import React, {useMemo, useState} from 'react';
import './App.css';

import {getGuestCookie, getSessionCookie, setSessionCookie} from "./context/context";
import {BrowserRouter, Navigate, Outlet, Route, Routes, useLocation} from "react-router-dom";
import {Home} from "./routes/Home/Home";
import {Layout} from "./routes/Layout";
import {Access} from './routes/Access/Access';
import {Share} from './routes/Share/Share';
import {ShareDatasetBasics} from './routes/Share/ShareDatasetBasics';
import {ShareDataset} from './routes/Share/ShareDataset';
import {ShareDatasetSuppInfo} from './routes/Share/ShareDatasetSuppInfo';
import {Resources} from './routes/Resources/Resources';
import {Faq} from './routes/Resources/Faq';
import {LsafVideos} from './routes/Resources/LsafVideos';
import {Terms} from './routes/Privacy/Terms';
import {Privacy} from './routes/Privacy/Privacy';
import {Registration} from "./routes/Registration/Registration";
import {ContactUs} from "./routes/ContactUs/ContactUs";
import {DataShareAgreement} from "./routes/DataShareAgreement/DataShareAgreement";
import {IUser, PDSGroup} from "./model/user/IUser";

import {NCIRequest} from "./routes/NCI/NCIRequest";
import {SubmissionResponse} from "./routes/SubmissionResponse/SubmissionResponse";
import {DataProviderRegistration} from "./routes/DataProvider/DataProviderRegistration";
import {DataProvider} from "./routes/DataProvider/DataProvider";
import {NCIDownloadApproval} from "./routes/NCI/NCIDownloadApproval";
import {NCIUploadApproval} from "./routes/NCI/NCIUploadApproval";
import {ForgotPassword} from "./routes/ForgotPassword/ForgotPassword";
import {AdminDashboard} from "./routes/Admin/AdminDashboard";
import {AdminUserRequests} from "./routes/Admin/AdminUserRequests";
import {AdminUserRequestAction} from "./routes/Admin/AdminUserRequestAction";
import {AdminDatasetRequestAction} from "./routes/Admin/AdminDatasetRequestAction";
import {AdminDSARequests} from "./routes/Admin/AdminDSARequests";
import {AdminDSAUpload} from "./routes/Admin/AdminDSAUpload";
import {SelectFiles} from "./routes/Share/SelectFiles";
import {Login} from "./routes/forms/login/Login";
import {AdminTickets} from "./routes/Admin/AdminTickets";
import {AdminUserInfo} from "./routes/Admin/AdminUserInfo";
import {ShareDatasetBasicsRWD} from "./routes/Share/ShareDatasetBasicsRWD";
import {ShareDatasetRWD} from "./routes/Share/ShareDatasetRWD";
import {ShareDatasetSuppInfoRWD} from "./routes/Share/ShareDatasetSuppInfoRWD";
import {AdminDatasetsRequests} from "./routes/Admin/AdminDatasetsRequests";
import {AdminDatasetsFormNCI} from "./routes/Admin/AdminDatasetsFormNCI";
import {AdminDatasetInfo} from "./routes/Admin/AdminDatasetInfo";
import {ClinicalDrugAdditivity} from "./routes/clinicalDrug/ClinicalDrugAdditivity";
import {NCIRequestsView} from "./routes/NCI/NCIRequestsView";
import {AdminTicketAction} from "./routes/Admin/AdminTicketAction";
import {AdminSystem} from "./routes/Admin/AdminSystem";
import {ProcessPasswordReset} from "./routes/forms/password/ProcessPasswordReset";
import {ContributionContents} from "./routes/Access/ContributionContents";
import {BASE_PATH} from "./constants";
import {ExpiredTokenRegistration} from "./routes/Registration/ExpiredTokenRegistration";
import {AdminReporting} from "./routes/Admin/AdminReporting";
import {MyAccount} from "./routes/forms/User/MyAccount";
import {EmailPreferences} from "./routes/forms/User/EmailPreferences";
import {ServiceTickets} from "./routes/forms/User/ServiceTickets";
import {ServiceTicketAction} from "./routes/forms/User/ServiceTicketAction";
import {NoMatch} from "./routes/Errors/NoMatch";
import {AdminNCIRequestsByUser} from "./routes/Admin/AdminNCIRequestsByUser";
import {PDSContext} from "./context/PDSContext";
import {useRequest} from "./helper/useRequest";
import {IResponse} from "./model/response/IResponse";
import {DisabledContactUs} from "./routes/ContactUs/DisabledContactUs";
import {ClinicalDrugAdditivityDescription} from "./routes/clinicalDrug/ClinicalDrugAdditivityDescription";
import {AdminDatasetFileInfo} from "./routes/Admin/AdminDatasetFIleInfo";

const App: React.FC = () => {

    const [sessionUser, setSessionUser] = useState<IUser>(getSessionCookie());
    const value = { sessionUser, setSessionUser };

    /**
     * handle successful call to API
     *
     * @param response - response back from api
     */
    const handleSuccess = (response: IResponse<IUser>) => {
        setSessionUser(response.data);
        setSessionCookie(response.data);
    }

    /**
     * handle an error to API, this means that the jwt does not exist, so set to guest
     *
     * @param error - error object
     */
    const handleError = (error: object) => {
        setSessionUser(getGuestCookie());
    }

    // get user
    const metadataURL = process.env.REACT_APP_API_URL + "/api/public/user/session"
    const [requestState, getUser] = useRequest({
        url: metadataURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })
    //const {isLoading, data, error} = requestState;

    useMemo(() => {
        //console.log('getting user');
        getUser();
    }, [])

    /**
     * only non-logged in users can go here (login page and registration page
     *
     * @param redirectPath - page to home page
     * @constructor - constructor
     */
    const NonAuthRoute = ({redirectPath = '/'}) => {
        if (sessionUser.userID > 0) {
            return <Navigate to={redirectPath} replace/>;
        }
        return <Outlet/>;
    }

    /**
     * only approved users can see this page, if not logged in, go to login page
     *
     * @param loginPath - path to login page
     * @constructor - constructor
     */
    const ApprovedUserRoute = ({loginPath = BASE_PATH + 'login'}) => {
        const location = useLocation();
        if (sessionUser.userID < 0) {
            return <Navigate to={loginPath} replace state={{from: location}}/>;
        }
        return <Outlet/>;
    };

    /**
     * only approver and admin can see this page, if not logged in, go to login page
     *
     * @param loginPath - path to login page
     * @param notAuthorizedPath - path to 403 page
     * @constructor - constructor
     */
    const ProtectedApproverRoute = ({loginPath = BASE_PATH + 'login'}, {notAuthorizedPath = BASE_PATH + '/error/403'}) => {
        const location = useLocation();
        const user:IUser = getSessionCookie();
        if (user.userID < 0) {
            return <Navigate to={loginPath} replace state={{from: location}}/>;
        } else if ((sessionUser.pdsGroup !== PDSGroup.Admin) && (sessionUser.pdsGroup !== PDSGroup.Approver)) {
            return <Navigate to={notAuthorizedPath} replace/>;
        }
        return <Outlet/>;
    };

    /**
     * only admins can see this page, if not logged in, go to log in page
     *
     * @param loginPath - path to login page
     * @param notAuthorizedPath - path to 403 page
     * @constructor - constructor
     */
    const ProjectedAdminRoute = ({loginPath = BASE_PATH + 'login'}, {notAuthorizedPath = BASE_PATH + '/error/403'}) => {
        const location = useLocation();
        if (sessionUser.userID < 0) {
            return <Navigate to={loginPath} replace state={{from: location}}/>;
        } else if (sessionUser.pdsGroup !== PDSGroup.Admin) {
            return <Navigate to={notAuthorizedPath} replace/>;
        }
        return <Outlet/>;
    }

    /**
     * only data providers and above can access this page, if not logged in, go to log in page
     *
     * @param loginPath - path to login page
     * @param notAuthorizedPath - path to 403 page
     * @constructor - constructor
     */
    const DataProviderRoute = ({loginPath = BASE_PATH + 'login'}, {notAuthorizedPath = BASE_PATH + '/error/403'}) => {
        if (sessionUser.userID < 0) {
            return <Navigate to={loginPath} replace/>;
        } else if ((sessionUser.pdsGroup !== PDSGroup.Provider) &&
            (sessionUser.pdsGroup !== PDSGroup.Admin) &&
            (sessionUser.pdsGroup !== PDSGroup.Approver)) {
            return <Navigate to={notAuthorizedPath}/>;
        }
        return <Outlet/>;
    };

    return (
        <BrowserRouter>
            <PDSContext.Provider value={value}>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Navigate to={BASE_PATH + "home"} replace/>}/>
                        <Route path={BASE_PATH + "home"} element={<Home/>}/>
                        <Route path={BASE_PATH + "access"} element={<Access/>}/>
                        <Route path={BASE_PATH + "contributionContents/:donationId"} element={<ContributionContents/>}/>
                        <Route path={BASE_PATH + "share"} element={<Share/>}/>
                        <Route path={BASE_PATH + "resources"} element={<Resources/>}/>
                        <Route path={BASE_PATH + "faq"} element={<Faq/>}/>
                        <Route path={BASE_PATH + "lsafVideos"} element={<LsafVideos/>}/>
                        <Route path={BASE_PATH + "terms"} element={<Terms/>}/>
                        <Route path={BASE_PATH + "privacy"} element={<Privacy/>}/>
                        <Route path={BASE_PATH + "contactUs"} element={<ContactUs/>}/>
                        <Route path={BASE_PATH + "contactUs/:email"} element={<DisabledContactUs/>}/>
                        <Route path={BASE_PATH + "submissionResponse"} element={<SubmissionResponse/>}/>
                        <Route element={<NonAuthRoute/>}>
                            <Route path={BASE_PATH + "login"} element={<Login/>}/>
                            <Route path={BASE_PATH + "forgotPassword"} element={<ForgotPassword/>}/>
                            <Route path={BASE_PATH + "forgotPassword/:status"} element={<ForgotPassword/>}/>
                            <Route path={BASE_PATH + "registration"} element={<Registration/>}/>
                            <Route path={BASE_PATH + "registration/rmi/:token"} element={<Registration/>}/>
                            <Route path={BASE_PATH + "registration/expired/:token"}
                                   element={<ExpiredTokenRegistration/>}/>
                            <Route path={BASE_PATH + "resetPassword/:dept/:title"} element={<ProcessPasswordReset/>}/>
                        </Route>
                        <Route element={<DataProviderRoute/>}>
                            <Route path={BASE_PATH + "dataShareAgreement"} element={<DataShareAgreement/>}/>
                            <Route path={BASE_PATH + "dataShareAgreement/:donationId"} element={<DataShareAgreement/>}/>
                            <Route path={BASE_PATH + "shareDatasetBasics/:donationId"} element={<ShareDatasetBasics/>}/>
                            <Route path={BASE_PATH + "shareDatasetBasicsRWD/:donationId"}
                                   element={<ShareDatasetBasicsRWD/>}/>
                            <Route path={BASE_PATH + "shareDatasetBasics/:donationId"} element={<ShareDatasetBasics/>}/>
                            <Route path={BASE_PATH + "shareDataset/:donationId"} element={<ShareDataset/>}/>
                            <Route path={BASE_PATH + "shareDatasetRWD/:donationId"} element={<ShareDatasetRWD/>}/>
                            <Route path={BASE_PATH + "shareDatasetSuppInfo/:donationId"}
                                   element={<ShareDatasetSuppInfo/>}/>
                            <Route path={BASE_PATH + "shareDatasetSuppInfoRWD/:donationId"}
                                   element={<ShareDatasetSuppInfoRWD/>}/>
                            <Route path={BASE_PATH + "selectFiles/:donationId"} element={<SelectFiles/>}/>
                        </Route>
                        <Route element={<ApprovedUserRoute/>}>
                            <Route path={BASE_PATH + "nciRequest/:requestId?"} element={<NCIRequest/>}/>
                            <Route path={BASE_PATH + "nciRequestsView"} element={<NCIRequestsView/>}/>
                            <Route path={BASE_PATH + "myAccount"} element={<MyAccount/>}/>
                            <Route path={BASE_PATH + "emailPreferences"} element={<EmailPreferences/>}/>
                            <Route path={BASE_PATH + "serviceTickets"} element={<ServiceTickets/>}/>
                            <Route path={BASE_PATH + "serviceTicketAction/:ticketId/:requestType"}
                                   element={<ServiceTicketAction/>}/>
                            <Route path={BASE_PATH + "nciDownloadApproval/:requestId?"}
                                   element={<NCIDownloadApproval/>}/>
                            <Route path={BASE_PATH + "nciUploadApproval/:requestId"} element={<NCIUploadApproval/>}/>
                            <Route path={BASE_PATH + "dataProvider"} element={<DataProvider/>}/>
                            <Route path={BASE_PATH + "dataProviderRegistration"} element={<DataProviderRegistration/>}/>
                            <Route path={BASE_PATH + "registration/reactivate/:token"} element={<Registration/>}/>
                        </Route>
                        <Route element={<ProtectedApproverRoute/>}>
                            <Route path={BASE_PATH + "admin/dashboard"} element={<AdminDashboard/>}/>
                            <Route path={BASE_PATH + "admin/tickets/:requestType"} element={<AdminTickets/>}/>
                            <Route path={BASE_PATH + "admin/tickets/processTicket/:ticketId/:requestType"}
                                   element={<AdminTicketAction/>}/>
                            <Route path={BASE_PATH + "admin/request/users/:requestType"}
                                   element={<AdminUserRequests/>}/>
                            <Route path={BASE_PATH + "admin/request/accounts/:requestId/:requestType"}
                                   element={<AdminUserRequestAction/>}/>
                            <Route path={BASE_PATH + "admin/request/dsa/:requestType"} element={<AdminDSARequests/>}/>
                            <Route path={BASE_PATH + "admin/request/datasets/:requestType"}
                                   element={<AdminDatasetsRequests/>}/>
                            <Route path={BASE_PATH + "admin/request/datasets/donationInfo/:donationId"}
                                   element={<AdminDatasetInfo/>}/>
                            <Route path={BASE_PATH + "admin/request/datasets/fileInfo/:donationId"}
                                   element={<AdminDatasetFileInfo/>}/>
                            <Route path={BASE_PATH + "admin/request/datasets/status/:donationId"}
                                   element={<AdminDatasetRequestAction/>}/>
                            <Route path={BASE_PATH + "admin/request/datasets/form/NCI/"}
                                   element={<AdminDatasetsFormNCI/>}/>
                            <Route path={BASE_PATH + "admin/request/dsa/details/:shareId"} element={<AdminDSAUpload/>}/>
                            <Route path={BASE_PATH + "admin/userInfo/:userId"} element={<AdminUserInfo/>}/>
                            <Route path={BASE_PATH + "admin/reporting"} element={<AdminReporting/>}/>
                            <Route path={BASE_PATH + "admin/nci/getRequestsByUser"}
                                   element={<AdminNCIRequestsByUser/>}/>
                        </Route>
                        <Route element={<ProjectedAdminRoute/>}>
                            <Route path={BASE_PATH + "admin/system"} element={<AdminSystem/>}/>
                            <Route path={BASE_PATH + "clinicalDrugAdditivity"} element={<ClinicalDrugAdditivity/>}/>
                            <Route path={BASE_PATH + "clinicalDrugAdditivityDescription"} element={<ClinicalDrugAdditivityDescription/>}/>
                        </Route>
                        <Route path={"*"} element={<NoMatch/>}/>
                    </Route>
                </Routes>
            </PDSContext.Provider>
        </BrowserRouter>

    );
}

export default App;
